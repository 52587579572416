import gsap from "gsap";
import { SPEED, STARTSHIFT, textAnnotations } from "./svgElements";

let STARTY =
  window.innerWidth > window.innerHeight
    ? window.innerHeight * 0.6
    : window.innerHeight * 0.7;
let MARKERWIDTH =
  window.innerWidth > window.innerHeight
    ? window.innerWidth * 0.02
    : window.innerHeight * 0.03;

export const animateElements = (svgElemetns, svgRef) => {
  STARTY =
    window.innerWidth > window.innerHeight
      ? window.innerHeight * 0.6
      : window.innerHeight * 0.7;
  MARKERWIDTH =
    window.innerWidth > window.innerHeight
      ? window.innerWidth * 0.02
      : window.innerHeight * 0.03;

  const isVertical = window.innerWidth < window.innerHeight;
  svgElemetns.forEach((element, index) => {
    switch (element.animType) {
      case "mainCircle":
        animateMainCircle(element, svgRef);
        break;
      case "hline":
        animateHLine(element, svgRef);
        break;
      case "vline":
        animateVLine(element, svgRef);

        break;
      case "annotationCircle":
        animateAnnotationCircle(element, svgRef);
        break;
      case "annotationText":
        animateAnnotationText(element, svgRef);
        break;
      case "annotationNumber":
        animateAnnotationText(element, svgRef);
        break;
    }
  });
};

export const animateLastAnnotations = (annotationsRef, svgRef) => {
  const words = annotationsRef.current.querySelectorAll(".wordC");
  const startShift = 230;
  gsap.fromTo(
    words,
    {
      y: 10000,
      x: 0,
      autoAlpha: 0,
    },
    {
      x: 0,
      y: 0,
      stagger: 0.1,
      autoAlpha: 1,
      scrollTrigger: {
        trigger: svgRef.current,
        start: getCoordinates(STARTSHIFT + MARKERWIDTH * startShift),
        end: getCoordinates(STARTSHIFT + MARKERWIDTH * (startShift + 5)),
        scrub: true,
      },
    }
  );
};
export const animateAnnotations = (annotationsRef, svgRef) => {
  const idx = 8;
  for (let index = 0; index < textAnnotations.length; index++) {
    const items = annotationsRef.current.children[index];
    const startShift = idx + 33 * index;
    const words = items.querySelectorAll(".wordC");
    gsap.fromTo(
      words,
      {
        y: 10000,
        x: 0,
        autoAlpha: 0,
      },
      {
        x: 0,
        y: 0,
        stagger: 0.1,
        autoAlpha: 1,
        scrollTrigger: {
          trigger: svgRef.current,
          start: getCoordinates(STARTSHIFT + MARKERWIDTH * startShift),
          end: getCoordinates(STARTSHIFT + MARKERWIDTH * (startShift + 5)),
          scrub: true,
        },
      }
    );

    // words.forEach((word, idx) => {
    //   const angle = Math.random() * 360;
    //   const distance = Math.max(window.innerWidth, window.innerHeight) * 0.5;

    //   gsap.fromTo(
    //     word,
    //     {
    //       x: Math.sin(angle) * distance,
    //       y: Math.cos(angle) * distance,
    //       rotate: 0,
    //     },
    //     {
    //       autoAlpha: 1,
    //       x: 0,
    //       y: 0,
    //       rotate: 0,
    //       scale: 1,
    //       delay: idx,
    //       scrollTrigger: {
    //         trigger: svgRef.current,
    //         start: getCoordinates(STARTSHIFT * 1.2 + MARKERWIDTH * startShift),
    //         end: getCoordinates(
    //           STARTSHIFT * 1.2 + MARKERWIDTH * (startShift + 5)
    //         ),
    //         scrub: true,
    //       },
    //     }
    //   );
    // });

    // in the last iteration here must be overlfow visible in line
    if (index === textAnnotations.length - 1) {
      const lines = items.querySelectorAll(".line");
      lines.forEach((line, idx) => {
        gsap.fromTo(
          line,
          {
            overflow: "hidden",
          },
          {
            overflow: "visible",
            scrollTrigger: {
              trigger: svgRef.current,
              start: getCoordinates(
                STARTSHIFT + MARKERWIDTH * (startShift + 15)
              ),
              end: getCoordinates(STARTSHIFT + MARKERWIDTH * (startShift + 16)),
              scrub: true,
            },
          }
        );
      });
      words.forEach((word, idx) => {
        const angle = Math.random() * 360;
        const distance = Math.max(window.innerWidth, window.innerHeight) * 2;
        gsap.fromTo(
          word,
          {
            x: 0,
            y: 0,
            rotate: 0,
            scale: 1,
          },
          {
            x: Math.sin(angle) * distance,
            y: Math.cos(angle) * distance,
            rotate: Math.random() * 180,
            delay: idx,
            scrollTrigger: {
              trigger: svgRef.current,
              start: getCoordinates(
                STARTSHIFT + MARKERWIDTH * (startShift + 25)
              ),
              end: getCoordinates(STARTSHIFT + MARKERWIDTH * (startShift + 40)),
              scrub: true,
            },
          }
        );
      });
    } else {
      gsap.fromTo(
        words,
        {
          y: 0,
          x: 0,
        },
        {
          y: 0,
          x: 0,
          // x: 10000,
          // y: 10000,
          autoAlpha: 0,
          scrollTrigger: {
            trigger: svgRef.current,
            start: getCoordinates(STARTSHIFT + MARKERWIDTH * (startShift + 25)),
            end: getCoordinates(STARTSHIFT + MARKERWIDTH * (startShift + 26)),
            scrub: true,
          },
        }
      );
    }
  }
};

// const tl2 = gsap.timeline({
//   scrollTrigger: {
//     trigger: svgRef.current,
//     start: getCoordinates(STARTSHIFT + MARKERWIDTH * (idx + 2)),
//     end: getCoordinates(
//       STARTSHIFT + MARKERWIDTH * (idx + 2) + startX * SPEED * 0.01
//     ),
//     scrub: true,
//     repeat: -1,
//   },
// });
// tl2.fromTo(
//   text.ref.current,
//   { attr: { "font-size": 0 } },
//   {
//     attr: { "font-size": Math.min(20, window.innerWidth * 0.04) },
//   }
// );
// console.log("annimation", annotationsRef.current.children[0]);
// const tl = gsap.timeline({
//   scrollTrigger: {
//     trigger: svgRef.current,
//     start: "top+=500 center",
//     end: "top+=600 center",
//     scrub: true,
//
//   },
// });
// tl.fromTo(
//   annotationsRef.current.children[0],
//   { left: "-1000px" }, // Start scaling at 1 (normal size)
//   { left: "0px" } // Scale up to 10 times the original size
// );
// };

export const getCoordinates = (pxFromTop) => {
  return `top+=${pxFromTop} center`;
};

const animateMainCircle = (circle, svgRef) => {
  const idx = 8;
  const isVertical = window.innerWidth < window.innerHeight;
  for (let index = 0; index < textAnnotations.length; index++) {
    const startShift = idx + 33 * index;

    gsap.fromTo(
      circle.ref.current,
      {
        attr: {
          "stroke-width": 12,
        },
      },
      {
        attr: {
          "stroke-width": isVertical ? "100" : "180",
        },
        scrollTrigger: {
          trigger: svgRef.current,
          start: getCoordinates(
            STARTSHIFT * 1.2 + MARKERWIDTH * (startShift - 1)
          ),
          end: getCoordinates(STARTSHIFT * 1.2 + MARKERWIDTH * startShift),
          scrub: true,
        },
      }
    );

    let endRadius = isVertical
      ? window.innerHeight * 0.2
      : window.innerWidth * 0.15;
    let duration = 5;
    if (index === textAnnotations.length - 1) {
      endRadius = Math.max(window.innerWidth, window.innerHeight);
      duration = 20;
      const latency = isVertical ? 5 : 0;
      gsap.fromTo(
        circle.ref.current,
        {
          attr: {
            cx: isVertical ? window.innerWidth * 0.5 : window.innerWidth * 0.75,
            "stroke-width": isVertical ? "100" : "180",
            r: isVertical
              ? window.innerHeight * 0.04
              : window.innerWidth * 0.05,
          },
        },
        {
          attr: {
            cx: window.innerWidth * 1.6,
            "stroke-width": 0,
            r: endRadius,
          },
          scrollTrigger: {
            trigger: svgRef.current,
            start: getCoordinates(
              STARTSHIFT * 1.2 + MARKERWIDTH * startShift + latency
            ),
            end: getCoordinates(
              STARTSHIFT * 1.2 + MARKERWIDTH * (startShift + duration)
            ),
            scrub: true,
          },
        }
      );
      break;
    }
    gsap.fromTo(
      circle.ref.current,
      {
        attr: {
          "stroke-width": isVertical ? "100" : "180",
          r: isVertical ? window.innerHeight * 0.04 : window.innerWidth * 0.05,
        },
      },
      {
        attr: {
          "stroke-width": 12,
          r: endRadius,
        },
        scrollTrigger: {
          trigger: svgRef.current,
          start: getCoordinates(STARTSHIFT * 1.2 + MARKERWIDTH * startShift),
          end: getCoordinates(
            STARTSHIFT * 1.2 + MARKERWIDTH * (startShift + duration)
          ),
          scrub: true,
        },
      }
    );
    let markers = false;
    let endShift = STARTSHIFT + MARKERWIDTH * (startShift + 32);
    let cx = isVertical ? window.innerWidth * 0.5 : window.innerWidth * 0.6;

    if (index === textAnnotations.length - 2) {
      cx = isVertical ? window.innerWidth * 0.5 : window.innerWidth * 0.75;
    }
    gsap.fromTo(
      circle.ref.current,
      {
        attr: {
          r: isVertical ? window.innerHeight * 0.2 : window.innerWidth * 0.15,
          cx: isVertical ? window.innerWidth * 0.5 : window.innerWidth * 0.6,
        },
      },
      {
        attr: {
          r: isVertical ? window.innerHeight * 0.04 : window.innerWidth * 0.05,
          cx: cx,
        },
        scrollTrigger: {
          trigger: svgRef.current,
          start: getCoordinates(STARTSHIFT + MARKERWIDTH * (startShift + 25)),
          end: getCoordinates(endShift),
          scrub: true,
          markers: markers,
        },
      }
    );
  }

  gsap.fromTo(
    circle.ref.current,
    {
      attr: {
        "stroke-width": 12,
        cy: window.innerHeight / 2,
        r: window.innerWidth / 3,
        cx: window.innerWidth * 0.5,
      },
    },
    {
      attr: {
        "stroke-width": 12,
        cy: STARTY,
        r: isVertical ? window.innerHeight * 0.04 : window.innerWidth * 0.05,
        cx: isVertical ? window.innerWidth * 0.5 : window.innerWidth * 0.6,
      },
      scrollTrigger: {
        trigger: svgRef.current,
        start: getCoordinates(window.innerHeight * 0.5),
        end: getCoordinates(window.innerHeight),
        scrub: true,
      },
    }
  );
};

const animateHLine = (line, svgRef) => {
  const animationLength = window.innerWidth;
  const startX = window.innerWidth * 0.9;

  gsap.set(line.ref.current, {
    attr: { y1: STARTY, y2: STARTY },
  });

  function createAnimation(line) {
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: svgRef.current,
        start: getCoordinates(STARTSHIFT + MARKERWIDTH * line.idx),
        end: getCoordinates(
          STARTSHIFT + MARKERWIDTH * line.idx + startX * SPEED
        ),
        scrub: true,
        repeat: -1,
        ease: "none",
      },
    });
    t1.fromTo(
      line.ref.current,
      { attr: { x1: startX, x2: startX } },
      {
        attr: { x1: -animationLength, x2: -animationLength + MARKERWIDTH * 8 },
      }
    );
    if (line.idx > 100) {
      const t2 = gsap.timeline({
        scrollTrigger: {
          trigger: svgRef.current,
          start: getCoordinates(
            STARTSHIFT + MARKERWIDTH * (line.idx * 0.03 + 225)
          ),
          end: getCoordinates(
            STARTSHIFT +
              MARKERWIDTH * (line.idx * 0.03 + 225) +
              startX * SPEED * 0.01
          ),
          scrub: true,
          repeat: -1,
          ease: "none",
        },
      });

      // Randomly generating angles and directions for the animation
      const angle = Math.random() * 360; // Random angle for rotation
      const distance = window.innerWidth * 3; // Ensuring it moves off the screen
      const deltaX = distance * Math.cos((angle * Math.PI) / 180); // Calculate x displacement
      const deltaY = distance * Math.sin((angle * Math.PI) / 180); // Calculate y displacement

      t2.fromTo(
        line.ref.current,
        {
          attr: { y1: STARTY, y2: STARTY },
          rotation: 0,
          transformOrigin: "50% 50%", // Ensure rotation occurs around the center
        },
        {
          duration: 3, // Adjust duration based on desired speed
          ease: "power1.in", // Easing function to accelerate the lines as they move
          attr: {
            y1: STARTY + window.innerHeight * 0.01 * 11,
            y2: STARTY + window.innerHeight * 0.01 * 11,
          },
          rotation: angle, // Apply the random rotation
          x: "+=" + deltaX, // Move horizontally
          y: "+=" + deltaY, // Move vertically
        }
      );
    }
  }

  createAnimation(line);
};
const animateVLineVertical = (line, svgRef) => {
  const viewportHeight = window.innerHeight;
  const startX = window.innerWidth * 0.9;

  function createAnimation(line) {
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: svgRef.current,
        start: getCoordinates(STARTSHIFT + MARKERWIDTH * line.idx),
        end: getCoordinates(
          STARTSHIFT + MARKERWIDTH * line.idx + viewportHeight * SPEED
        ),
        scrub: true,

        repeat: -1,
        ease: "none",
      },
    });

    t1.fromTo(
      line.ref.current,
      { attr: { y1: startX - 100, y2: startX } },
      { attr: { y1: 0, y2: 0 } }
    );
  }

  createAnimation(line);
};

const animateVLine = (line, svgRef) => {
  const animationLength = window.innerWidth;
  const startX = window.innerWidth * 0.9;
  const startY = STARTY;

  function createAnimation(line) {
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: svgRef.current,
        start: getCoordinates(STARTSHIFT + MARKERWIDTH * line.idx),
        end: getCoordinates(
          STARTSHIFT + MARKERWIDTH * line.idx + startX * SPEED
        ),
        scrub: true,
        repeat: -1,
        ease: "none",
      },
    });
    const t2 = gsap.timeline({
      scrollTrigger: {
        trigger: svgRef.current,
        start: getCoordinates(STARTSHIFT + MARKERWIDTH * line.idx),
        end: getCoordinates(
          STARTSHIFT + MARKERWIDTH * line.idx + startX * SPEED * 0.01
        ),
        scrub: true,
        repeat: -1,
        ease: "none",
      },
    });
    t2.fromTo(
      line.ref.current,
      { attr: { y1: startY, y2: startY } },
      {
        attr: {
          y1: startY + line.minusLength,
          y2: startY - line.length,
        },
        duration: 1,
      }
    );
    t1.fromTo(
      line.ref.current,
      { attr: { x1: startX, x2: startX } },
      {
        attr: { x1: -animationLength, x2: -animationLength },
        duration: 1,
      }
    );
    if (line.idx > 100) {
      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: svgRef.current,
          start: getCoordinates(
            STARTSHIFT + MARKERWIDTH * (line.idx * 0.03 + 225)
          ),
          end: getCoordinates(
            STARTSHIFT +
              MARKERWIDTH * (line.idx * 0.03 + 225) +
              startX * SPEED * 0.01
          ),
          scrub: true,
          repeat: -1,
          ease: "none",
        },
      });

      // Randomly generating angles and directions for the animation
      const angle = Math.random() * 360; // Random angle for rotation
      const distance = window.innerWidth * 3; // Ensuring it moves off the screen
      const deltaX = distance * Math.cos((angle * Math.PI) / 180); // Calculate x displacement
      const deltaY = distance * Math.sin((angle * Math.PI) / 180); // Calculate y displacement

      tl2.fromTo(
        line.ref.current,
        () => ({
          attr: {
            x1: gsap.getProperty(line.ref.current, "x1"),
            x2: gsap.getProperty(line.ref.current, "x2"),
          },
          rotation: 0,
          transformOrigin: "50% 50%",
        }),
        {
          duration: 3,
          ease: "power1.in",
          attr: { x1: -animationLength, x2: -animationLength },
          rotation: angle,
          x: "+=" + deltaX,
          y: "+=" + deltaY,
        }
      );
    }
  }

  createAnimation(line);
};

const animateAnnotationCircle = (circle, svgRef) => {
  const startX = window.innerWidth * 0.9;
  const animationLength = window.innerWidth;
  gsap.set(circle.ref.current, {
    attr: { cy: STARTY - window.innerHeight * 0.01 * 11 },
  });
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: svgRef.current,
      start: getCoordinates(STARTSHIFT + MARKERWIDTH * circle.idx),
      end: getCoordinates(
        STARTSHIFT + MARKERWIDTH * circle.idx + startX * SPEED
      ),
      scrub: true,
      repeat: -1,
      ease: "none",
    },
  });

  const t2 = gsap.timeline({
    scrollTrigger: {
      trigger: svgRef.current,
      start: getCoordinates(STARTSHIFT + MARKERWIDTH * (circle.idx + 2)),
      end: getCoordinates(
        STARTSHIFT + MARKERWIDTH * (circle.idx + 2) + startX * SPEED * 0.01
      ),
      scrub: true,
      repeat: -1,
    },
  });
  t2.fromTo(
    circle.ref.current,
    { attr: { r: 0 } },
    {
      attr: {
        r: 2,
      },
    }
  );
  tl.fromTo(
    circle.ref.current,
    { attr: { cx: startX } },
    { attr: { cx: -animationLength } }
  );
};

const animateAnnotationText = (text, svgRef) => {
  const startX = window.innerWidth * 0.9;
  const animationLength = window.innerWidth;
  gsap.set(text.ref.current, {
    attr: {
      y: text.y,
      "font-size": text.fontSize,
    },
  });
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: svgRef.current,
      start: getCoordinates(STARTSHIFT + MARKERWIDTH * text.idx),
      end: getCoordinates(STARTSHIFT + MARKERWIDTH * text.idx + startX * SPEED),
      scrub: true,
      repeat: -1,
      ease: "none",
    },
  });
  tl.fromTo(
    text.ref.current,
    { attr: { x: startX } },
    { attr: { x: -animationLength } }
  );

  const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: svgRef.current,
      start: getCoordinates(STARTSHIFT + MARKERWIDTH * text.idx),
      end: getCoordinates(
        STARTSHIFT + MARKERWIDTH * (text.idx + 0.1) + startX * SPEED * 0.01
      ),
      scrub: true,
      repeat: -1,
    },
  });
  tl2.fromTo(
    text.ref.current,
    { attr: { "font-size": 0 } },
    {
      attr: { "font-size": text.fontSize },
    }
  );
};

const animateLastCircle = (circle, svgRef) => {
  const startShift = 8 + 33 * textAnnotations.length - 12;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: svgRef.current,
      start: getCoordinates(STARTSHIFT * 1.2 + MARKERWIDTH * startShift),
      end: getCoordinates(STARTSHIFT * 1.2 + MARKERWIDTH * (startShift + 5)),
      scrub: true,
    },
  });

  tl.fromTo(
    circle.ref.current,
    {
      attr: {
        "stroke-width": 0,
        cy: STARTY,
        r: Math.max(window.innerWidth, window.innerHeight) * 0.05,
        cx: window.innerWidth * 0.6,
        fill: "transparent",

        // cy: window.innerHeight / 2,
        // r: 0,
        // fill: "rgba(145,254,175,255)",
        // cx: window.innerWidth / 2,
      },
    },
    {
      attr: {
        "stroke-width": 0,
        cy: STARTY,
        r: Math.max(window.innerWidth, window.innerHeight),
        cx: window.innerWidth * 0.6,
        fill: "white",
        // cy: STARTY,
        // r: Math.max(window.innerWidth, window.innerHeight),
        // fill: "rgba(255,255,255,255)",
        // cx: window.innerWidth * 0.5,
      },
    }
  );
};

const animateAnnotationNumber = (text, svgRef) => {
  const startX = window.innerWidth * 0.9;
  const animationLength = window.innerWidth;

  // Set initial attributes
  gsap.set(text.ref.current, {
    attr: {
      y: text.y,
      "font-size": text.fontSize,
      text: 0, // Starting text
    },
  });

  // Timeline for moving the text
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: svgRef.current,
      start: getCoordinates(STARTSHIFT + MARKERWIDTH * text.idx),
      end: getCoordinates(STARTSHIFT + MARKERWIDTH * text.idx + startX * SPEED),
      scrub: true,
      repeat: -1,
      ease: "none",
    },
  });

  tl.fromTo(
    text.ref.current,
    { attr: { x: startX } },
    { attr: { x: -animationLength }, duration: 10, ease: "none" }
  );
  // Timeline for font-size animation
  const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: svgRef.current,
      start: getCoordinates(STARTSHIFT + MARKERWIDTH * text.idx),
      end: getCoordinates(
        STARTSHIFT + MARKERWIDTH * (text.idx + 0.1) + startX * SPEED * 0.01
      ),
      scrub: true,
      repeat: -1,
    },
  });
  tl2.fromTo(
    text.ref.current,
    { attr: { "font-size": 0 } },
    { attr: { "font-size": text.fontSize } }
  );
};
