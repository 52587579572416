import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/e2-logo.svg"; // Import the SVG file

function Header() {
  const navigate = useNavigate();
  const [logoWidth, setLogoWidth] = useState(
    Math.min(Math.max(window.innerHeight, window.innerWidth) * 0.3, 250)
  );

  useEffect(() => {
    // const handleResize = () => {
    //   setLogoWidth(
    //     Math.min(Math.max(window.innerHeight, window.innerWidth) * 0.3, 250)
    //   );
    // };
    // window.addEventListener("resize", handleResize);
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  const handleHomeClick = () => {
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smoothly scrolls to the top of the page
    } else {
      navigate("/"); // Navigates to the home page
    }
  };

  return (
    <header>
      <img
        src={Logo}
        alt="Home"
        onClick={handleHomeClick}
        style={{
          cursor: "pointer",
          width: `${logoWidth}px`,
          top: "4vh",
          left: "4vh",
          position: "fixed",
          zIndex: 100,
        }}
      />
    </header>
  );
}

export default Header;
