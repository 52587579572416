import React, { forwardRef } from "react";
import "./LastAnnotation.css";
const LastAnnotation = forwardRef((props, ref) => {
  let lines = [
    "we are effectively combating anarchonisms.",
    "don't hesitate to reach out to us for more information.",
  ];
  const isVertical = window.innerWidth < window.innerHeight;
  if (isVertical) {
    lines = [
      "we are effectively",
      "combating anarchonisms.",
      "don't hesitate to",
      "reach out for more",
      "information.",
    ];
  }
  return (
    <div className="abc" ref={ref}>
      {lines.map((line, lineIdx) => (
        <div className="abcLine" key={lineIdx}>
          {line.split(" ").map((wordData, wordIdx) => (
            <div key={wordIdx} className="wordC">
              {wordData}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
});

export default LastAnnotation;
