import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cross from "../assets/cross.svg"; // Import the SVG file
import "./Footer.css";

function Footer() {
  const [iconWidth, setIconWidth] = useState(
    Math.min(
      Math.max(20, Math.max(window.innerHeight, window.innerWidth) * 0.05),
      40
    )
  );

  useEffect(() => {
    // const handleResize = () => {
    //   setIconWidth(
    //     Math.min(
    //       Math.max(20, Math.max(window.innerHeight, window.innerWidth) * 0.05),
    //       40
    //     )
    //   );
    // };
    // window.addEventListener("resize", handleResize);
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  const textStyle = {
    fontSize: `${iconWidth / 3}px`, // Sets the text size to be half of the icon width
    textDecoration: "none",
    color: "inherit",
  };

  return (
    <div className="footerContainer">
      <div className="leftButton">
        <Link
          to="/about"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 200,
          }}
        >
          <img
            src={Cross}
            alt="Cross"
            style={{ width: `${iconWidth}px`, cursor: "pointer" }}
          />
          <span style={textStyle}>About</span>
        </Link>
      </div>
      <div className="rightButton">
        <Link
          to="/contact"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 200,
          }}
        >
          <img
            src={Cross}
            alt="Cross"
            style={{ width: `${iconWidth}px`, cursor: "pointer" }}
          />
          <span style={textStyle}>Contact</span>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
