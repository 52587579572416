import React, { forwardRef, useEffect, useRef } from "react";
import { textAnnotations } from "../utils/svgElements";
import "./ScrollAnnotations.css";
const ScrollAnnotations = forwardRef((props, ref) => {
  const refs = useRef(textAnnotations.map(() => React.createRef()));
  useEffect(() => {
    console.log("ScrollAnnotation");
  }, []);

  return (
    <div className="annotationContainer" ref={ref}>
      {textAnnotations.map((quote, index) => (
        <div className="text" key={index}>
          {quote.lines.map((line, lineIdx) => (
            <div className="line" key={lineIdx}>
              {line.split(" ").map((wordData, wordIdx) => (
                <div key={wordIdx} className="wordC">
                  {wordData}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
});

export default ScrollAnnotations;
