import React, { useEffect, useState } from "react";
import { useScramble } from "use-scramble";

const texts = [
  {
    title: "About E2",
    body: "Founded in 2023, Emerging Evidence is at the forefront of innovation, boasting a highly-trained team of data engineers and interdisciplinary experts with extensive experience in artificial intelligence. Our mission is to harness the power of the latest models and best practices to address complex, interdisciplinary challenges. Committed to innovation, we strive to eliminate outdated practices by applying cutting-edge AI solutions, ensuring that our approaches are as modern and effective as possible. At Emerging Evidence, we transform data into insights and insights into action, driving progress across industries.",
  },
  {
    title: "Our Mission",
    body: "Our mission at Emerging Evidence, founded in 2023, is to revolutionize industry standards through the strategic application of artificial intelligence, spearheaded by our team of expert data engineers and interdisciplinary professionals. We focus on employing cutting-edge models and practices to effectively tackle complex challenges across multiple fields. Dedicated to overcoming outdated methodologies, we integrate innovative AI technologies to ensure our solutions are both modern and effective. At Emerging Evidence, we are committed to turning data into actionable insights, driving transformative progress in various sectors.",
  },
  {
    title: "Innovation at Our Core",
    body: "Innovation at Our Core: Emerging Evidence, founded in 2023, embodies the forefront of innovation with its elite team of data engineers and interdisciplinary specialists in artificial intelligence. We are dedicated to employing the most recent models and best practices to solve complex, cross-disciplinary issues. Actively combating anachronisms, our mission is to phase out obsolete methods by integrating advanced AI solutions, thereby keeping our practices modern and efficient. At Emerging Evidence, we transform data into actionable insights, catalyzing progress across various industries.",
  },
];

function About() {
  const [index, setIndex] = useState(0);

  // Scramble hooks
  const { ref: titleRef, replay: replayTitleScramble } = useScramble({
    text: texts[index].title,
    speed: 1,
    tick: 1,
    step: 8,
    scramble: 10,
  });

  const { ref: bodyRef, replay: replayBodyScramble } = useScramble({
    text: texts[index].body,
    speed: 1,
    tick: 1,
    step: 8,
    scramble: 10,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (index + 1) % texts.length;
      setIndex(nextIndex);
      replayTitleScramble();
      replayBodyScramble();
    }, 30000); // Change text every 30 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [index, replayTitleScramble, replayBodyScramble]);

  return (
    <div className="about">
      <h1 ref={titleRef}></h1>
      <p ref={bodyRef}></p>
    </div>
  );
}

export default About;
