import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import favicon from "./assets/fav.png"; // Adjust the path as necessary

// Importing pages
import About from "./pages/About";
import Contact from "./pages/Contact";
import Main from "./pages/Main";
import NotFound from "./pages/NotFound"; // Import the NotFound component

// Importing components
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  useEffect(() => {
    document.title = "Emerging Evidence";
  }, []); // Empty array ensures this runs only once when the component mounts
  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const head = document.getElementsByTagName("head")[0];
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      head.appendChild(newLink);
      newLink.href = favicon;
    } else {
      link.href = favicon;
    }
  }, []); // This runs only once
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Header />
      <Footer />
    </Router>
  );
}

export default App;
