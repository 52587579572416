import axios from "axios";
import React, { useState } from "react";
import "./Contact.css"; // Make sure to import the CSS file

function Contact() {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus("Sending...");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/api/v1/contact`,
        contact
      );
      setStatus("Message sent successfully!");
      setContact({ name: "", email: "", message: "" }); // Clear the form
    } catch (error) {
      console.error("ERROR:", error);
      setStatus("Failed to send message. Please try again later.");
    }
  };

  return (
    <div className="contact">
      <h1>Which data challenge may we solve for you?</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <input
          type="text"
          name="name"
          value={contact.name}
          onChange={handleChange}
          placeholder="Name"
          className="input-field"
        />
        <input
          type="email"
          name="email"
          value={contact.email}
          onChange={handleChange}
          placeholder="Email"
          className="input-field"
        />
        <textarea
          name="message"
          value={contact.message}
          onChange={handleChange}
          placeholder="Message"
          className="input-field"
        ></textarea>
        <button type="submit" className="submit-btn">
          Send
        </button>
        <p className="status-message">{status}</p>
      </form>
    </div>
  );
}

export default Contact;
