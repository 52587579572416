import React, { useEffect, useState } from "react";
import { useScramble } from "use-scramble";

const texts = [
  {
    title: "404",
    body: "The page you are looking for does not exist. Please check the URL and try again",
  },
  {
    title: "We have a problem",
    body: "Sorry, the page you are looking for cannot be found. Please check the URL and try again. Maybe you can find what you are looking for on our homepage.",
  },
  {
    title: "Not Found",
    body: "The page you are looking for is not available. Please check the URL and try again. You can also visit our homepage to find more information.",
  },
];

function NotFound() {
  const [index, setIndex] = useState(0);

  // Scramble hooks
  const { ref: titleRef, replay: replayTitleScramble } = useScramble({
    text: texts[index].title,
    speed: 1,
    tick: 1,
    step: 8,
    scramble: 10,
  });

  const { ref: bodyRef, replay: replayBodyScramble } = useScramble({
    text: texts[index].body,
    speed: 1,
    tick: 1,
    step: 8,
    scramble: 10,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (index + 1) % texts.length;
      setIndex(nextIndex);
      replayTitleScramble();
      replayBodyScramble();
    }, 30000); // Change text every 30 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [index, replayTitleScramble, replayBodyScramble]);

  return (
    <div className="about">
      <h1 ref={titleRef}></h1>
      <p ref={bodyRef}></p>
    </div>
  );
}

export default NotFound;
