import { useEffect, useState } from "react";
import { useScramble } from "use-scramble";
import "./AnimTitle.css";

const quotes = [
  "Emerging from the chasm of doubt, one finds Evidence not in the clarity of day but in the obscurity of night.",
  "In the dance of light, Evidence whispers to those Emerging from dreams, revealing that reality is but a shadow's echo.",
  "Time, in its unyielding march, leaves behind fragments of Evidence, Emerging as whispers from the future to redefine our present reality.",
];

const AnimTitle = () => {
  const [quoteIndex, setQuoteIndex] = useState(0);
  const { ref, replay } = useScramble({
    text: quotes[quoteIndex],
    speed: 1,
    tick: 1,
    step: 8,
    scramble: 10,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const nextQuoteIndex = (quoteIndex + 1) % quotes.length;
      setQuoteIndex(nextQuoteIndex);
      replay(); // Replay the animation with the new quote
    }, 10000);

    return () => clearInterval(interval);
  }, [quoteIndex, replay]);

  return (
    <div className="titleContainer">
      <div className="titleQuote" ref={ref}></div>
    </div>
  );
};

export default AnimTitle;
