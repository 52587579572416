import React from "react";
import AnimTimeLine from "../components/AnimTimeLine";

function Main() {
  const [isPortrait, setIsPortrait] = React.useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  React.useEffect(() => {
    const mql = window.matchMedia("(orientation: portrait)");
    const onChange = (e) => {
      setIsPortrait(e.matches);
    };

    mql.addEventListener("change", onChange);
    return () => mql.removeEventListener("change", onChange);
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <AnimTimeLine />
      {/* {isPortrait ? <AnimTimeLineV /> : <AnimTimeLine />} */}
    </div>
  );
}

export default Main;
