import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useHistory
import {
  animateAnnotations,
  animateElements,
  animateLastAnnotations,
  getCoordinates,
} from "../utils/animation";
import { NMARKERS, generateSVGElements } from "../utils/svgElements";
import "./AnimTimeLine.css";
import AnimTitle from "./AnimTitle";
import LastAnnotation from "./LastAnnotation";
import ScrollAnnotations from "./ScrollAnnotations";

const CANVASHEIGHT = NMARKERS + 100;

let MARKERWIDTH =
  window.innerWidth > window.innerHeight
    ? window.innerWidth * 0.02
    : window.innerHeight * 0.03;
let MARKERSPACING = MARKERWIDTH * 4;
gsap.registerPlugin(ScrollTrigger);

const AnimTimeLine = () => {
  const svgRef = useRef(null);
  const annoRef = useRef(null);
  const lastRef = useRef(null);
  let currentHeight = window.innerHeight;
  let currentWidth = window.innerWidth;
  const [height, setHeight] = useState(CANVASHEIGHT * MARKERWIDTH);
  const [width, setWidth] = useState(window.innerWidth * 0.99);
  const containerRef = useRef(null);
  const [svgElements, setSvgElements] = useState(generateSVGElements());
  const initialized = useRef(false);
  const navigate = useNavigate(); // Get the useNavigate hook for navigation

  // Helper to generate SVG elements

  useEffect(() => {
    const handleResize = () => {
      let MARKERWIDTH =
        window.innerWidth > window.innerHeight
          ? window.innerWidth * 0.02
          : window.innerHeight * 0.03;
      let MARKERSPACING = MARKERWIDTH * 4;
      const current = window.innerHeight + window.innerWidth;
      const last = currentHeight + currentWidth;
      const diff = Math.abs(current - last) / last;
      console.log("diff; ", Math.abs(current - last), diff);
      if (diff < 0.1) return;
      currentHeight = window.innerHeight;
      currentWidth = window.innerWidth;
      // Kill tweens for each SVG element reference
      svgElements.forEach((element) => {
        if (element.ref.current) {
          gsap.killTweensOf(element.ref.current);
        }
      });
      setSvgElements([]);

      // Update dimensions
      setHeight(CANVASHEIGHT * MARKERSPACING);
      setWidth(window.innerWidth);
      // Clear previous SVG elements and regenerate
      const newSvgElements = generateSVGElements();
      setSvgElements(newSvgElements); // Set new elements which triggers re-animation
      setTimeout(() => {
        animateElements(newSvgElements, svgRef);
      }, 10);
    };

    const debouncedHandleResize = debounce(handleResize, 300);
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [svgElements]);

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;
    if (svgElements.length > 0) {
      animateElements(svgElements, svgRef, height);
      animateAnnotations(annoRef, svgRef);
      animateLastAnnotations(lastRef, svgRef);
    }

    ScrollTrigger.create({
      trigger: svgRef.current,
      start: getCoordinates(265 * MARKERWIDTH),
      end: getCoordinates(266 * MARKERWIDTH),
      scrub: true,
      onEnter: (self) => {
        navigate("/contact"); // Navigate to /contact when scrolled to 99%
        window.scrollTo(0, 0); // Scroll to the top of the page
      },
    });
    // generateTextElements(textLines, containerRef);
  }, [height, svgElements]);

  useEffect(() => {
    // Ensuring the window starts from the top every time the component mounts
    window.scrollTo(0, 0);
    if (svgRef.current) {
      svgRef.current.scrollTop = 0;
      ScrollTrigger.refresh(); // Refresh ScrollTrigger instances to ensure proper calculations
    }
  }, []); // Empty dependency array ensures this only runs on mount

  return (
    <div
      style={{
        height: `${height}px`,
        width: `${width}px`,
        top: 0,
        left: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowX: "hidden",
      }}
      ref={containerRef}
    >
      <LastAnnotation ref={lastRef} />
      <AnimTitle svgRef={svgRef} />
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        ref={svgRef}
        className="svgContainer"
        style={{ position: "fixed", top: 0, left: 0 }}
      >
        {svgElements.map((element, index) => {
          switch (element.domType) {
            case "rectangle":
              return (
                <rect
                  key={index}
                  x={element.x}
                  y={element.y}
                  width={element.width}
                  height={element.height}
                  fill={element.fill}
                  ref={element.ref}
                />
              );
            case "smallCircle":
              return (
                <circle
                  key={index}
                  clipPath="url(#clip-circle)"
                  cx={element.cx}
                  cy={element.cy}
                  r={element.r}
                  fill={element.fill}
                  ref={element.ref}
                  strokeWidth={element.strokewidth}
                  stroke={element.stroke}
                />
              );
            case "line":
              return (
                <line
                  key={index}
                  x1={element.x1}
                  y1={element.y1}
                  x2={element.x2}
                  y2={element.y2}
                  ref={element.ref}
                  stroke={element.stroke}
                  strokeWidth={element.strokeWidth}
                />
              );
            case "circle":
              return (
                <circle
                  key={index}
                  cx={element.cx}
                  cy={element.cy}
                  r={element.r}
                  fill={element.fill}
                  ref={element.ref}
                  strokeWidth={element.strokewidth}
                  stroke={element.stroke}
                />
              );
            case "clipCircle":
              return (
                <defs>
                  <clipPath id="clip-circle">
                    <circle
                      key={index}
                      cx={element.cx}
                      cy={element.cy}
                      r={element.r}
                      fill={element.fill}
                      ref={element.ref}
                      strokeWidth={element.strokewidth}
                      stroke={element.stroke}
                    />
                  </clipPath>
                </defs>
              );
            case "text":
              return (
                <text
                  key={index}
                  clipPath="url(#clip-circle)"
                  x={element.x}
                  y={element.y}
                  fontSize={element.fontSize}
                  fill={element.fill}
                  ref={element.ref}
                  fontFamily="Bricolage Grotesque"
                  textAnchor={element.textAnchor} // This centers the text horizontally
                >
                  {element.content}
                </text>
              );
            case "longLine":
              return (
                <line
                  key={index}
                  x1={element.x1}
                  y1={element.y1}
                  x2={element.x2}
                  y2={element.y2}
                  clipPath="url(#clip-circle)"
                  ref={element.ref}
                  stroke={element.stroke}
                  strokeWidth={element.strokeWidth}
                />
              );
            case "lastCircle":
              return (
                <circle
                  key={"lastCircle"}
                  cx={element.cx}
                  cy={element.cy}
                  r={element.r}
                  fill={element.fill}
                  ref={element.ref}
                  strokeWidth={element.strokewidth}
                  stroke={element.stroke}
                />
              );

            default:
              return null;
          }
        })}
      </svg>
      <ScrollAnnotations ref={annoRef} svgRef={svgRef} />
    </div>
  );
};

export default AnimTimeLine;
